import React from "react";

// Customizable Area Start
import { ArrowBackIosSharp } from "@mui/icons-material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Box, Grid, FormControl } from "@mui/material";
import { Typography, DropDown } from "@builder/component-library";

// Customizable Area End

import MultitieredpricingController, {
  Props,
  configJSON,
  Option,
} from "./MultitieredpricingController";

export default class Multitieredpricing extends MultitieredpricingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box style={webStyles.navbar}>
          <Box
            data-test-id="handleHomeBack"
            onClick={this.handleHomeBack}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <ArrowBackIosSharp />
            <Typography variant="lg">{configJSON.backButtonText}</Typography>
          </Box>
          <Typography variant="lg" component="h2">
            {configJSON.multiteredPricingHeading}
          </Typography>
          <ShoppingCartIcon id="handleCart" onClick={this.handleCart} />
        </Box>
        <Box style={webStyles.mainBox}>
          <Grid container spacing={8}>
            <>
              <Grid item xs={12} sm={12} md={6} style={webStyles.leftBox}>
                <Box style={webStyles.cards}>
                  <img
                    src={this.state.productImage}
                    alt="image"
                    style={{ width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                style={webStyles.cardContent as React.CSSProperties}
              >
                <Box style={{ width: "600px" }}>
                  <Typography variant="lg">{this.state.productName}</Typography>
                  <Typography variant="sm">
                    {this.state.productDescription}
                  </Typography>
                  <Box>
                    <Grid container style={webStyles.priceTypeBox} spacing={2}>
                      {this.state.allProductsMultitiered?.map(
                        (option: Option, index) => (
                          <Grid item xs={4} sm={4} key={index}>
                            <Box
                              style={
                                this.state.selectedPriceType ===
                                option.attributes.quality_range
                                  ? webStyles.activePriceType
                                  : webStyles.priceType
                              }
                              data-test-id="selectedPriceType"
                              onClick={() =>
                                this.handleClick(
                                  option.attributes.quality_range,
                                  option
                                )
                              }
                            >
                              <Typography
                                style={
                                  this.state.selectedPriceType ===
                                  option.attributes.quality_range
                                    ? webStyles.activePriceTitle
                                    : webStyles.priceTitle
                                }
                              >
                                {option.attributes.quality_range}
                              </Typography>
                              <Box style={{ paddingBottom: "20px" }}>
                                <Typography>
                                  {option.attributes.description ?? ""}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        )
                      )}
                    </Grid>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "50px",
                      }}
                    >
                      <Box>
                        <FormControl>
                          <DropDown
                            placeHolder="Resolution"
                            testID="handleResolution"
                            options={this.state.allResolutionType}
                            onSelect={(item) =>
                              this.handleResolution(item.name)
                            }
                          />
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl>
                          <DropDown
                            placeHolder="User Type"
                            options={this.state.allUserType}
                            onSelect={(item) => this.handleUserType(item.name)}
                            headerTypographyProps={{
                              variant: "xs",
                            }}
                            itemTypographyProps={{
                              variant: "xs",
                            }}
                            testID="handleUserType"
                          />
                        </FormControl>
                      </Box>
                      <Box>
                        <Typography variant="lg">
                          price: ${this.state.price}
                        </Typography>
                        <Box
                          style={webStyles.addBtn as React.CSSProperties}
                          data-test-id="handleAddToCart"
                          onClick={this.handleAddToCart}
                        >
                          Add to Cart
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </>
          </Grid>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  mainBox: {
    padding: "80px",
  },
  cardContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  title: {
    fontSize: "18px",
    fontWeight: 600,
  },
  leftBox: {
    display: "flex",
    justifyContent: "center",
  },
  cards: {
    borderRadius: "8px",
    maxWidth: "500px",
    minWidth: "500px",
  },
  price: {
    fontSize: "16px",
    fontWeight: 500,
  },
  content: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#949494",
  },
  navbar: {
    borderBottom: "1px solid gray",
    display: "flex",
    padding: "25px",
    justifyContent: "space-around",
  },
  heading: {
    color: "#000000",
    fontWeight: 600,
    fontSize: "20px",
  },
  priceType: {
    border: "1px solid #D3D3D3",
  },
  activePriceType: {
    border: "1px solid #000000",
  },
  priceTitle: {
    color: "black",
    background: "#D3D3D3",
  },
  activePriceTitle: {
    color: "#ffffff",
    background: "#000000",
  },
  priceTypeBox: {
    marginTop: "20px",
  },
  addToCartBox: {
    display: "flex",
    justifyContent: "space-between",
    textAlign: "end",
  },
  mainPrice: {
    fontSize: "20px",
  },
  addBtn: {
    textTransform: "none",
    color: "#ffffff",
    background: "#008000",
    fontSize: "18px",
    fontWeight: 600,
    padding: 8,
    borderRadius: 8,
    cursor: "pointer",
  },
};
// Customizable Area End
