// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Multitieredpricing from "../../blocks/multitieredpricing/src/Multitieredpricing";
import MultitieredProduct from "../../blocks/multitieredpricing/src/MultitieredProduct";
import MultitieredCart from "../../blocks/multitieredpricing/src/MultitieredCart";


const routeMap = {
Multitieredpricing:{
 component:Multitieredpricing,
path:"/Multitieredpricing"},
Login:{
 component:Login,
path:"/Login"},
MultitieredProduct:{
 component:MultitieredProduct,
path:"/MultitieredProduct"},
MultitieredCart:{
 component:MultitieredCart,
path:"/MultitieredCart"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;